@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.admin-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: -50px;
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.header h2 {
  color: #333;
  font-family: 'Poppins', sans-serif;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
  font-family: 'Poppins', sans-serif;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  outline: none;
}

.button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
}

.button:hover {
  background-color: #0056b3;
}

.existing-posts {
  margin-top: 30px;
}

.post-item {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  text-align: center;
}

.post-item h4 {
  margin-bottom: 20px;
  color: #333;
  font-family: 'Poppins', sans-serif;
}

.post-item button {
  margin-top: 10px;
  background-color: #dc3545;
}

.post-item button:hover {
  background-color: #c82333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .admin-container {
    padding: 15px;
  }

  .form-group input,
  .form-group textarea,
  .button {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 567px) {
  .admin-container {
    padding: 10px;
    margin-top: 20px;
  }

  .form-group input,
  .form-group textarea,
  .button {
    font-size: 12px;
    padding: 8px;
  }
}
