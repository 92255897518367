@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Global styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Hero section styles */
.hero {
  position: relative;
  width: 100%;
  height: 50vh; /* Adjust the height as needed */
  background: url('https://i.ibb.co/d0nz8sp/bookscomp.png') center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.hero-content {
  padding: 0 1rem;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

/* Bookstore styles */
.bookstore {
  padding: 20px 10px;
  background-color: #f9f9f9;
  margin-top: 5px;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.card .text {
  margin-top: 15px;
}

.card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.card a {
  color: #0c0e0d;
  text-decoration: none;
  font-weight: 500;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .hero {
    height: 30vh; /* Adjust the height for smaller screens */
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .bookstore {
    margin-top: 0; /* Remove the negative margin for smaller screens */
  }

  .card {
    padding: 15px;
  }

  .card h3 {
    font-size: 1rem;
  }

  .card p {
    font-size: 0.9rem;
  }

  .card a {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .hero {
    height: 25vh; /* Adjust the height for smaller screens */
  }

  .hero h1 {
    font-size: 1.5rem;
  }

  .hero p {
    font-size: 0.875rem;
  }

  .card {
    padding: 10px;
  }

  .card h3 {
    font-size: 0.9rem;
  }

  .card p {
    font-size: 0.8rem;
  }

  .card a {
    font-size: 0.8rem;
  }
}
