@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Global styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Hero section styling */
.readersclub-hero {
  position: relative;
  width: 100%;
  height: 60vh; /* Adjust the height as needed */
  background: url('https://i.ibb.co/d0nz8sp/bookscomp.png') center/cover no-repeat;
}

/* ReadersClub section styling */
.readersclub-section {
  padding: 40px 20px;
  background-color: #f7f1f1;
  color: #333;
  margin-bottom: -3px;
  margin-top: 5px;
  text-align: center;
}

.readersclub-section h2 {
  margin-bottom: 20px;
  font-size: 28px;
  color: #d32f2f;
}

.readersclub-section p {
  max-width: 800px;
  margin: 0 auto 20px;
  line-height: 1.6;
  font-size: 18px;
}

.next-edition-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #d32f2f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
}

.next-edition-button:hover {
  background-color: #b71c1c;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .readersclub-hero {
    height: 40vh; /* Adjust the height for smaller screens */
  }

  .readersclub-section {
    padding: 30px 15px;
    margin-top: 20px;
  }

  .readersclub-section h2 {
    font-size: 24px;
  }

  .readersclub-section p {
    font-size: 16px;
    line-height: 1.5;
  }

  .next-edition-button {
    font-size: 16px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .readersclub-hero {
    height: 30vh; /* Adjust the height for smaller screens */
  }

  .readersclub-section {
    padding: 20px 10px;
    margin-top: 5px;
  }

  .readersclub-section h2 {
    font-size: 20px;
  }

  .readersclub-section p {
    font-size: 14px;
    line-height: 1.4;
  }

  .next-edition-button {
    font-size: 14px;
    padding: 6px 12px;
  }
}
