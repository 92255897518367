@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Utility classes */
.u-max-width-100 {
  max-width: 100%;
}

.u-height-auto {
  height: auto;
}

.u-object-cover {
  object-fit: cover;
}

.u-border-radius-8 {
  border-radius: 8px;
}

.u-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Newsletter styles */
.newsletter {
  background-color: #110303;
  color: #fff;
  padding: 10px 20px;  /* Reduced padding */
  font-family: 'Poppins', sans-serif;
}

.newsletter .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.newsletter .left {
  margin-bottom: 5px;
}

.newsletter h1 {
  color: #ffffff;
  font-size: 16px;  /* Reduced font size */
  font-weight: 400;
}

.newsletter input {
  padding: 5px;
  border: none;
  outline: none;
  margin-right: 10px;
  width: 200px;  /* Reduced width */
  font-size: 14px;  /* Adjusted font size */
}

.newsletter button {
  padding: 5px;
  color: #fff;
  background-color: #1eb2a6;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;  /* Adjusted font size */
  transition: background-color 0.3s ease;
}

.newsletter button:hover {
  background-color: #148f7a;
}

/* Footer styles */
footer {
  background-color: #dfd9d9;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

footer .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  gap: 20px;
}

footer .logo {
  text-align: center;
}

footer .logo p {
  color: grey;
  margin: 10px 0;
}

footer .social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

footer .icon {
  background-color: transparent;
  color: rgb(22, 1, 1);
  margin-top: 10px;
}

footer .company-logo {
  width: 100px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
}

footer h3 {
  margin-bottom: 15px;
  font-weight: 600;
  text-align: center;
}

footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

footer ul li {
  margin-bottom: 10px;
}

footer .link li::before {
  content: "⟶";
  color: #1eb2a6;
  margin-right: 5px;
}

footer .link li a {
  color: #34495e;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer .link li a:hover {
  color: #48bad6;
}

footer .last ul li {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

footer .last i {
  width: 30px;
  font-size: 12px;
  color: #1eb2a6;
}

.legal {
  text-align: center;
  padding: 10px 0;
  color: rgba(231, 229, 229, 0.932);
  background-color: #110303;
}

.legal p {
  font-size: 12px;
}

.legal i {
  color: #09998d;
}

/* Scroll to top button */
.scroll-to-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: transparent;
  color: #fcf6f6;
  border: none;
  border-radius: 50%;
  padding: 7px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.scroll-to-top:hover {
  background-color: #9fe5f1;
}

.scroll-to-top i {
  font-size: 18px;
}

/* Specific styles for footer blog links */
.footer-blog {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.footer-blog-item {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.footer-blog-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.footer-blog-img img {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  object-fit: cover;
}

.footer-blog-text span {
  font-size: 12px;
  color: grey;
  margin-right: 10px;
}

.footer-blog-text h4 {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.container .footer-blog {
  margin-top: 10px;
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media screen and (max-width: 800px) {
  .newsletter .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .newsletter h1 {
    font-size: 14px;  /* Adjusted font size */
    margin-bottom: 10px;
  }

  .newsletter input {
    width: 100%;
    margin-bottom: 10px;
  }

  .newsletter button {
    width: 100%;
  }

  footer .container {
    flex-direction: column;
    align-items: center;
  }
}
