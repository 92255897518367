@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700');

/* General styles */
.blog-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Adjust the height as needed */
  background: url('https://cdn.wallpapersafari.com/80/57/D3uOMH.jpg') center/cover no-repeat; /* Replace with your hero image URL */
  background-size: cover;
  background-position: center;
  position: relative;
}

.blog-title {
  font-size: 4rem;
  color: black;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* BlogCard container styles */
.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background-color: #f7f1f1;
}

.blog-link {
  text-decoration: none;
  color: inherit;
}

.container {
  padding: 20px;
}

.container .grid2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 440px;
}

.items {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.items:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.items .img {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.items .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.items .text {
  padding: 15px;
}

.items .text h1 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.4;
  margin: 10px 0;
  transition: color 0.3s ease;
  color: black;
}

.items .text h1:hover {
  color: #1e28b2;
  cursor: pointer;
}

.items .text span {
  text-transform: uppercase;
  font-weight: 500;
  color: #1eb2a6;
}

.items .text span label {
  margin-left: 10px;
  color: grey;
  font-size: 0.875rem;
}

.items .text span:nth-last-child(1) label {
  color: #881eb2;
}

.items .text p {
  color: grey;
  font-size: 0.875rem;
  margin-top: 10px;
}

/* Post content styles */
.post-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -120px;
  margin-bottom: 10px;
}

.post-img {
  width: 90%;
  max-width: 600px;
  margin-bottom: 20px;
  min-width: auto;
  margin-top: 130px;
}

.post-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
}

.post-text {
  max-width: 800px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.post-text h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.youtube-link {
  margin-top: 20px;
}

.youtube-link a {
  color: #1e28b2;
  font-weight: bold;
  text-decoration: none;
}

.youtube-link a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .container .grid2 {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .items {
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 100px;
  }

  .post-content {
    margin-top: 5px;
  }
}

@media screen and (min-width: 1200px) {
  .items {
    max-width: 350px;
  }
}
