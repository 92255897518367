@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Global styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Hero section styles */
.howwe-hero {
  position: relative;
  width: 100%;
  height: 60vh; /* Adjust the height as needed */
  background: url('https://i.pinimg.com/564x/3e/ce/c3/3ecec359a1348a4ebb8f7bb466497fd0.jpg') center/cover no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.howwe-hero  .hero-content {
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5); /* Optional: Add a background to improve text readability */
  padding: 0px;
  border-radius: 10px;

}

.howwe-hero .hero-content h1 {
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  animation: fadeIn 2s ease-in-out;

}

/* Animation for the text */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* How We section styles */
.howwe-section {
  padding: 40px 20px;
  background-color: #f7f1f1;
  color: #333;
  margin-bottom: 10px;
  margin-top: -35px;
}

.howwe-section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: #110808;
  font-size: 3rem;
}

.howwe-section p {
  max-width: 800px;
  margin: 0 auto 20px;
  line-height: 1.6;
  font-size: 18px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .howwe-hero {
    height: 40vh; /* Adjust the height for smaller screens */
  }

  .hero-content h1 {
    font-size: 1rem;
  }

  .howwe-section {
    padding: 10px 20px;
    margin-top: 20px;
  }

  .howwe-section h2 {
    font-size: 18px;
  }

  .howwe-section p {
    font-size: 15px;
    line-height: 1.5;
  }
}

@media (max-width: 480px) {
  .howwe-hero {
    height: 30vh; /* Adjust the height for smaller screens */
  }

  .hero-content h1 {
    font-size: 1.5rem;
  }

  .howwe-section {
    padding: 20px 10px;
    margin-top: 5px;
  }

  .howwe-section h2 {
    font-size: 22px;
  }

  .howwe-section p {
    font-size: 14px;
    line-height: 1.4;
  }
}
 