/* General styles for the Hero section */
.hero {
  position: relative;
  width: 100%;
  height: 60vh; /* Adjust the height as needed */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  filter: brightness(0.9); /* Darken the image for better text contrast */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-content {
  position: relative;
  z-index: 1;
  padding: 0 1rem;
  text-align: center;
  color: white;
  max-width: 80%;
  margin: auto;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-style: italic;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add text shadow for better readability */
  color: white; /* Ensure the text color is white */
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-style: italic;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Add text shadow for better readability */
  color: white; /* Ensure the text color is white */
}

/* Decorative Divider */
.divider {
  width: 80%;
  height: 4px;
  background-color: #ccc;
  margin: 2rem auto; /* Adjust the margin to control the spacing */
  border-radius: 2px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .hero {
    height: 40vh; /* Adjust the height for smaller screens */
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .divider {
    width: 90%;
    margin: 1.5rem auto; /* Adjust the margin for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .hero {
    height: 30vh; /* Adjust the height for extra small screens */
  }

  .hero h1 {
    font-size: 1.5rem;
  }

  .hero p {
    font-size: 0.875rem;
  }

  .divider {
    width: 95%;
    margin: 1rem auto; /* Adjust the margin for extra small screens */
  }
}

@media screen and (max-width: 360px) {
  .hero {
    height: 25vh; /* Adjust the height for very small screens */
  }

  .hero h1 {
    font-size: 1.2rem;
  }

  .hero p {
    font-size: 0.75rem;
  }

  .divider {
    width: 100%;
    margin: 0.5rem auto; /* Adjust the margin for very small screens */
  }
}

@media screen and (max-width: 320px) {
  .hero {
    height: 20vh; /* Adjust the height for very small screens */
  }

  .hero h1 {
    font-size: 1rem;
  }

  .hero p {
    font-size: 0.625rem;
  }

  .divider {
    width: 100%;
    margin: 0.5rem auto; /* Adjust the margin for very small screens */
  }
}
