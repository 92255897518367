@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700');

.testimonial-container {
  max-width: 900px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 70px;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}

.animated-title {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 2.7rem;
  line-height: 1.1;
  animation: fadeInUp 1.5s ease-in-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slider-container {
  position: relative;
  user-select: none;
  padding-inline: 1rem;
}

.slider-container .quote {
  position: absolute;
  z-index: -1;
}

.slider-container .top-quote {
  top: -1rem;
  left: -1rem;
}

.slider-container .bottom-quote {
  bottom: -3rem;
  right: -0.5rem;
  transform: rotate(180deg);
}

.splide__track {
  border-radius: 1rem;
}

.splide__slide {
  display: flex;
  align-items: center;
  background-color: #1c1c1c;
  padding: 3rem 1.875rem;
  border-radius: 1rem;
  gap: 1rem;
  text-align: center;
}

.splide__slide .content {
  max-width: 600px;
  margin: 0 auto;
}

.slider-container .content .text {
  color: #f7f5f5;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-style: italic;
}

.slider-container .content .user,
.slider-container .content .post {
  font-weight: 600;
  color: #f7f5f5;
}

.splide__pagination__page.is-active {
  background-color: #9b072c;
}

.splide__arrow {
  background-color: white;
  border: none;
  font-size: 2rem;
  color: #fdfcfc;
  font-weight: 500;
}

.splide__arrow--prev {
  top: 50%;
  left: -3.5rem;
}

.splide__arrow--next {
  top: 50%;
  right: -3.5rem;
}

.splide__pagination__page {
  height: 10px;
  width: 10px;
  opacity: 1;
}

@media (max-width: 576px) {
  .splide__slide {
    display: block;
    text-align: center;
  }

  .splide__arrow {
    font-size: 1.5rem;
  }

  .splide__arrow--prev {
    left: 0.5rem;
  }

  .splide__arrow--next {
    right: 0.5rem;
  }

  .slider-container .bottom-quote {
    right: 0;
  }

  .slider-container .top-quote {
    margin-top: 350px;
  }
}
