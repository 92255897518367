@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700');

.faq-section {
  padding: 40px 20px;
  background-color: #fff;
  color: #aa1010;
  margin-top: 5px;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}

.animated-title {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 2.7rem;
  line-height: 1.1;
  animation: fadeInUp 1.5s ease-in-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.faq-list {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.faq-item {
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  transition: background-color 0.3s ease;
}

.faq-item:hover {
  background-color: #f1f1f1;
}

.faq-question {
  font-weight: bold;
  font-size: 18px;
  position: relative;
  margin-top: 5px;
  transition: color 0.3s ease;
}

.faq-question::after {
  content: "\002B"; /* Plus symbol */
  font-size: 20px;
  position: absolute;
  right: 0;
  transition: transform 0.3s ease;
}

.faq-answer {
  font-size: 16px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  padding: 0;
}

.faq-answer.active {
  max-height: 200px; /* Adjust based on expected content height */
  padding: 10px 0;
}

.faq-item.active .faq-question::after {
  transform: rotate(45deg);
}

.faq-recap {
  max-width: 800px;
  margin: 40px auto;
  line-height: 1.6;
}

.faq-recap h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #d32f2f;
}

.faq-recap p {
  font-size: 16px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .faq-section {
    padding: 30px 15px;
    margin-top: 220px;
  }

  .animated-title {
    font-size: 2rem;
  }

  .faq-list {
    font-size: 14px;
  }

  .faq-answer {
    font-size: 14px;
  }

  .faq-recap h2 {
    font-size: 20px;
  }

  .faq-recap p {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .faq-section {
    padding: 20px 10px;
    margin-top: 150px;
  }

  .animated-title {
    font-size: 1.5rem;
  }

  .faq-list {
    font-size: 12px;
  }

  .faq-answer {
    font-size: 12px;
  }

  .faq-recap h2 {
    font-size: 18px;
  }

  .faq-recap p {
    font-size: 12px;
  }
}
