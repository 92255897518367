.skeleton-loader {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  
  .skeleton-header {
    width: 60%;
    height: 2rem;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .skeleton-image {
    width: 100%;
    height: 15rem;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .skeleton-text {
    width: 100%;
    height: 1.5rem;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  