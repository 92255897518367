@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Global styling */
.contacts {
  padding: 10px 0;
  margin-top: 40px; /* Adjusted for better positioning */
  background-color: #f3eeee;
}

/* Hero section styling */
.contact-hero {
  position: relative;
  width: 100%;
  height: 50vh;
  background: url('https://ecmas.in/wp-content/uploads/2019/12/contact-us.jpg') center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.contact-hero-content {
  text-align: center;
}

.contact-hero h1 {
  font-size: 3rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.contact-hero p {
  font-size: 1.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

/* Iframe styling */
.contacts iframe {
  width: 100%;
  height: 90%; /* Ensure full height */
  border: none;
}

/* Flex container styling */
.contacts .container {
  display: flex;
  flex-direction: row;
  padding: 50px; /* Reduced padding */
  background-color: #f7f1f1;
  gap: 20px; /* Gap between left and right sections */
  margin-bottom: -40px;
  margin-top: -50px;
}

/* Left section styling */
.contacts .left {
  flex: 1;
}

/* Right section styling */
.contacts .right {
  flex: 1;
  padding: 20px; /* Reduced padding */
}

/* Heading styling */
.contacts h1 {
  font-size: 3rem; /* Adjusted font size */
  font-weight: 500;
  margin-bottom: 20px;
}

/* Paragraph styling */
.contacts p {
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 400;
}

/* Item grid styling */
.contacts .items {
  margin: 30px 0;
  width: 100%; /* Ensuring full width */
  background: transparent;
  color: black;
  display: flex; /* Display items in a row */
  flex-wrap: wrap; /* Ensure items wrap to next line if necessary */
  gap: 20px; /* Space between items */
  box-shadow: none; /* Ensure no shadow */
  transition: none; /* Ensure no transition */
}

/* Box styling within items */
.contacts .box {
  background: none;
  box-shadow: none; /* Ensure no shadow */
  transition: none; /* Ensure no transition */
  padding: 10px; /* Adding some padding for better spacing */
  flex: 1 1 calc(50% - 20px); /* Ensure two items per row with gap */
  box-sizing: border-box;
}

/* Box heading styling */
.contacts .box h4 {
  font-size: 16px;
  margin-bottom: 5px;
  transition: none; /* Ensure no transition */
}

/* Box link and paragraph styling */
.contacts .box a,
.contacts .box p {
  font-size: 14px; /* Adjusted font size */
  color: #d32f2f;
  text-decoration: none;
  transition: none; /* Ensure no transition */
  background: none; /* Ensure no background change */
  box-shadow: none; /* Ensure no shadow */
}

/* Remove hover effect and animation */
.contacts .box a:hover,
.contacts .social-links a:hover,
.contacts .items a:hover,
.contacts .box a,
.contacts .social-links a,
.contacts .items a {
  color: #d32f2f;
  text-shadow: none; /* Ensure no text shadow */
  background: none; /* Ensure no background change */
  box-shadow: none; /* Ensure no shadow */
  transition: none; /* Ensure no transition */
  animation: none; /* Ensure no animation */
}

/* Input and textarea styling */
.contacts textarea,
.contacts input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid grey;
  border-radius: 5px;
  transition: none; /* Ensure no transition */
  animation: none; /* Ensure no animation */
}

/* Flexbox for form inputs */
form .flexSB {
  display: flex;
  gap: 10px;
}

/* Subheading styling */
.contacts h3 {
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

/* Social links styling */
.contacts .social-links {
  margin-bottom: 20px; /* Add margin-bottom to separate from form */
}

.contacts .social-links a {
  margin-right: 15px;
  color: #d32f2f;
  transition: none; /* Ensure no transition */
  animation: none; /* Ensure no animation */
}

/* Button styling */
.contacts .primary-btn {
  width: 200px;
  height: 50px;
  border: none;
  outline: none;
  background: #2f2f2f;
  color: #f3eeee;
  font-size: 16px;
  border-radius: 40px;
  text-align: center;
  box-shadow: 0 6px 20px -5px rgba(235, 61, 61, 0.9);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 3px;
}

.contacts .primary-btn .check-box {
  width: 80px;
  height: 90px;
  border-radius: 40px;
  box-shadow: 0 0 12px -2px rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  right: -30px;
  opacity: 5;
}

.contacts .primary-btn .check-box svg {
  width: 40px;
  margin: 20px;
}

.contacts .primary-btn svg path {
  stroke-width: 3;
  stroke: #fff;
  stroke-dasharray: 34;
  stroke-dashoffset: 34;
  stroke-linecap: round;
}

/* Active button styling */
.contacts .primary-btn.active {
  background: #025022;
  transition: 3s;
  
}

.contacts .primary-btn.active .check-box {
  right: 0;
  opacity: 1;
  transition: s;
}

.contacts .primary-btn.active p {
  margin-right: 125px;
  transition: 1s;
}

.contacts .primary-btn.active svg path {
  stroke-dashoffset: 0;
  transition: 1s;
  transition-delay: 1s;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  /* Hero section adjustments for small screens */
  .contact-hero {
    height: 30vh;
  }

  .contact-hero h1 {
    font-size: 2rem;
  }

  .contact-hero p {
    font-size: 1rem;
  }

  /* Column direction for small screens */
  .contacts .container {
    flex-direction: column;
    padding: 20px;
  }

  /* Full width for left and right sections */
  .contacts .left,
  .contacts .right {
    width: 100%;
    padding: 10px; /* Reduced padding for small screens */
  }

  /* Adjust iframe height */
  .contacts iframe {
    height: 60vh;
  }

  /* Full width rows on small screens */
  .contacts .row {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .contacts iframe {
    height: 40vh;
  }

  .contact-hero h1 {
    font-size: 1.5rem;
  }

  .contact-hero p {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 360px) {
  .contacts iframe {
    height: 30vh;
  }

  .contact-hero h1 {
    font-size: 1.2rem;
  }

  .contact-hero p {
    font-size: 0.75rem;
  }

  .contacts .social-links {
    margin-bottom: 10px; /* Adjust margin for small screens */
  }

  .contacts .box {
    flex: 1 1 100%; /* Stack items vertically */
    padding: 5px; /* Adjust padding for small screens */
  }

  .contacts h1 {
    font-size: 2rem; /* Adjusted font size */
  }

  .contacts p {
    font-size: 1rem; /* Adjusted font size */
  }
}

@media screen and (max-width: 320px) {
  .contacts iframe {
    height: 20vh;
  }

  .contact-hero h1 {
    font-size: 1rem;
  }

  .contact-hero p {
    font-size: 0.625rem;
  }
}
