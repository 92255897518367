@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.home {
  padding: 1rem;
}

@media (max-width: 768px) {
  .home {
    padding: 0.5rem;
  }
}
